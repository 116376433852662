<template>
  <tj-dialog v-model="visible" title="员工列表" width="900px" @close="onClose">
    <el-container>
      <el-header height="auto">
        <div class="fr">
          <el-button
            type="primary"
            :icon="CirclePlus"
            @click="add"
          >新增员工</el-button>
        </div>
      </el-header>
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="序号" type="index" align="right"></el-table-column>
          <el-table-column label="姓名" prop="user_name"></el-table-column>
          <el-table-column label="手机号" align="center">
            <template #default="{ row }">
              <el-link
                v-if="row.mobile"
                type="primary"
                :icon="Phone"
                :underline="false"
                :href="`tel:${row.mobile}`"
              >{{ row.mobile }}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="类型" align="center" width="100">
            <template #default="{ row }">
              <template v-if="row.type === 1">普通员工</template>
              <el-tag v-if="row.type === 2" type="success">负责人</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="160">
            <template #default="{ row }">
              <el-button
                type="text"
                :disabled="row.type === 2"
                @click="changeHeader(row.id)"
              >设为负责人</el-button>
              <el-button
                type="text"
                class="color-danger"
                :icon="Delete"
                @click="remove(row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
  </tj-dialog>
  <Add ref="addRef" @submit="getList"></Add>
</template>

<script setup>
import { find } from 'lodash';
import { ref } from 'vue';
import { ElMessageBox } from 'element-plus';
import { CirclePlus, Phone, Delete } from '@element-plus/icons-vue';
import { useDialog, usePagination } from '@tj/ui';
import Add from './Add.vue';
import request from '@/hooks/request';

const addRef = ref();

let companyId = 0;

const { page } = usePagination();
const tableData = ref([]);
function getList() {
  request.get('/admin/company/staffList', {
    params: {
      company_id: companyId,
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const {
  visible,
  open,
  onOpen,
} = useDialog();
defineExpose({ open });
onOpen((id) => {
  companyId = id;
  getList();
});
function onClose() {
  companyId = 0;
  tableData.value = [];
}

function changeHeader(staffId) {
  const finded = find(tableData.value, { type: 2 });
  request.post('/admin/company/changeHeader', {
    company_id: companyId,
    company_user_id: staffId,
    previous_user_id: finded ? finded.id : null,
  }).then(() => {
    getList();
  });
}

function add() {
  addRef.value.open(companyId);
}

function remove(staff) {
  ElMessageBox.confirm(`删除员工：${staff.user_name}，是否继续？`, '提示', {
    type: 'warning',
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  }).then(() => {
    request.post('/admin/company/delStaffAdmin', {
      company_user_id: [staff.id],
    }).then(() => {
      getList();
    });
  });
}
</script>
