<template>
  <tj-dialog v-model="visible" title="新增员工" width="300px">
    <el-form ref="formRef" label-width="auto" :model="detail" :rules="rules">
      <el-form-item label="姓名：" prop="name">
        <el-input v-model.trim="detail.name"></el-input>
      </el-form-item>
      <el-form-item label="联系方式：" prop="mobile">
        <el-input v-model.trim="detail.mobile"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';

const defaultStaffInfo = {
  name: '',
  mobile: '',
};

const detail = ref({ ...defaultStaffInfo });

const rules = {
  name: { required: true, message: '姓名不能为空' },
  mobile: { required: true, message: '联系方式不能为空' },
};

let companyId = 0;
const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((id) => {
  companyId = id;
});

const emit = defineEmits(['submit']);
const formRef = ref();
function submit() {
  formRef.value.validate((valid) => {
    if (valid) {
      request.post('/admin/company/addStaffAdmin', {
        ...detail.value,
        company_id: companyId,
      }).then(() => {
        emit('submit');
        close();
      });
    }
  });
}

defineExpose({ open });
</script>
